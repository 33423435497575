import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ProductionCardService {
    readProductionCardQuery(){
        var query = `query($paging:ServerPaging, $status:String, $type:String){
                        GetProduction(Paging:$paging, status:$status, ProuductionType:$type){
                            ProductionResponse {
                                production_id
                                production_code
                                production_type
                                status
                                production_result_item_id
                                production_result_item_name
                                production_result_product_code
                                production_result_quantity
                                production_result_uom
                                created_at
                                last_update
                                waste_percentage
                                storage_id
                                machine_id
                                machine_name
                                booking_order_id
                                producer_id
                                producer_name
                                production_actual_quantity
                                production_reject_item_id
                                production_reject_quantity
                                production_start_date_est
                                production_finish_date_est
                                production_start_date
                                production_finish_date
                                production_material {
                                    production_id
                                    item_id
                                    item_name
                                    product_code
                                    type_detail_id
                                    uom
                                    quantity
                                    waste_quantity
                                    actual_quantity
                                    formula_id
                                    barcode
                                    item_type
                                    active_flag
                                    required_type_detail_quantity
                                    created_at
                                    last_update
                                    ready_quantity
                                }
                            }
                            Total
                        }
                    }`;
        return query;
    }

    readProductionCardDetailQuery(){
        var query = gql`query($id:Int){
            GetProduction(ProductionId: $id){
                ProductionResponse {
                    production_id
                    production_code
                    production_type
                    status
                    production_result_item_id
                    production_result_item_name
                    production_result_product_code
                    production_result_quantity
                    production_result_uom
                    created_at
                    last_update
                    waste_percentage
                    storage_id
                    machine_id
                    machine_name
                    booking_order_id
                    producer_id
                    producer_name
                    production_actual_quantity
                    production_reject_item_id
                    production_reject_quantity
                    production_start_date_est
                    production_finish_date_est
                    production_start_date
                    production_finish_date
                    production_material {
                        production_id
                        item_id
                        item_name
                        product_code
                        type_detail_id
                        uom
                        quantity
                        waste_quantity
                        actual_quantity
                        formula_id
                        barcode
                        item_type
                        active_flag
                        required_type_detail_quantity
                        created_at
                        last_update
                        ready_quantity
                    }
                }
                Total
            }
        }`;
        return query;
    }

    async getDetailProductionCard(id){
        const variables = {
            id : id
        }

        var query = this.readProductionCardDetailQuery();
        
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetProduction.ProductionResponse[0];
    }

    async getStorageQuery(){
        var query = gql`query{
            GetStorage{
                storage_id
                storage_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var label = result.data.GetStorage[i].storage_name
            var str = {value:result.data.GetStorage[i].storage_id, label:label}
            storageData.push(str);
        }

        return storageData;
    }

    async getMachineQuery(){
        var query = gql`query{
            GetMachine(active_flag:"A"){
                machine_id
                machine_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var machineData = [];
        for (let i = 0; i < result.data.GetMachine.length; i++) {
            var label = result.data.GetMachine[i].machine_name
            var str = {value:result.data.GetMachine[i].machine_id, label:label}
            machineData.push(str);
        }

        return machineData;
    }

    async getItemFormulaQuery(id){
        var variables = {id : id.toString()};
        var query = gql`query($id:String!){
            GetFormulaByMachineId(MachineId:$id){
                formula_id
                formula_name
                machine_id
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query,variables);
        var data = [];
        if(result.data.GetFormulaByMachineId != null){
            for (let i = 0; i < result.data.GetFormulaByMachineId.length; i++) {
                var str = { value: result.data.GetFormulaByMachineId[i].formula_id, 
                            label: result.data.GetFormulaByMachineId[i].formula_name}
                data.push(str);
            }
        }

        return data;
    }

    async getItemMasterData(id){
        var variables = {
            id : id.toString()
        };

        var query = gql`
            query($id:String!) {
                GetProductMaster(itemID:$id) {
                    ProductMaster {
                        item_id
                        product_code
                        item_name
                    }
                    Total
                }
            }
        `;

        var result = await globalfunc.defaultApolloQueryDefault(query,variables);
        var data = [];
        if(result.data.GetProductMaster != null){
            for (let i = 0; i < result.data.GetProductMaster.Total; i++) {
                var str = { 
                    value: result.data.GetProductMaster.ProductMaster[i].item_id, 
                    label: result.data.GetProductMaster.ProductMaster[i].item_name
                }
                data.push(str);
            }
        }

        return data;
    }

    async getFormulaRecipeQuery(variables){
        var query = gql`query($id:String!, $quantity:Float!){
            GetProductionFormula(ItemId:$id, Quantity:$quantity){
                formula_id
                type_detail_id
                waste_percentage
                quantity
                waste_quantity
                total_quantity
                item_id
                product_code
                item_name
                barcode
                item_type
                active_flag
                base_uom
                base_weight
                ready_quantity
            }
        }`;

        var result = await globalfunc.defaultApolloQueryDefault(query,variables);
        var data = this.formulaCount(result.data.GetProductionFormula, "New")
        return data;
    }

    formulaCount(data, saveType){
        var result = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var qty = 0;
                var total_quantity = 0;
                var actual_quantity = 0;

                if (saveType == "Edit") {
                    qty = data[i].quantity;
                    total_quantity = data[i].required_type_detail_quantity;
                    actual_quantity = data[i].actual_quantity == null ? 0 : data[i].actual_quantity;
                }
                else if (saveType == "New") {
                    qty = 0;
                    total_quantity = data[i].total_quantity;
                    actual_quantity = 0;
                }
                
                var str = { 
                    item_id : data[i].item_id,
                    product_code : data[i].product_code,
                    item_name : data[i].item_name,
                    waste_percentage : parseFloat(data[i].waste_percentage),
                    type_detail_id : data[i].type_detail_id,
                    total_quantity : total_quantity,
                    qty : qty,
                    actual_quantity : actual_quantity,
                    ready_quantity : data[i].ready_quantity
                }   
                result.push(str);
            }
        }  
              
        return result;
    }

    async getMachineByItemId(id){
        var variables = {id : id};
        var query = gql`
            query ($id:String!) {
                GetMachineByItemId (itemId: $id) {
                    machine_id
                    machine_name
                    machine_type_id
                    active_flag
                    created_at
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query,variables);
        var machineData = [];
        for (let i = 0; i < result.data.GetMachineByItemId.length; i++) {
            var str = { value: result.data.GetMachineByItemId[i].machine_id, 
                        label: result.data.GetMachineByItemId[i].machine_name}
            machineData.push(str);
        }

        return machineData;
    }

    async getBookingOrderData(id) {
        const variables = {
            id : id
        }

        var query = gql`
            query ($id:Int!) {
                GetBookingOrder (BookingOrderID: $id) {
                    booking_order_v {
                        booking_order_id
                        purchase_order_id
                        item_id
                        quantity_base
                    }
                }
            }
        `;
        
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetBookingOrder.booking_order_v[0];
    }

    async addQuery(variables){
        var query = gql`
            mutation ($data:NewProduction!) {
                CreateProduction (NewProduction:$data)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`
            mutation ($id:Int!, $data:NewProduction!) {
                UpdateProduction (ProductionId:$id, NewProduction:$data)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    validateGrid(data){
        if(data.length > 0){
            var validateIndex = 0;
            for (let i = 0; i < data.length; i++) {
                if(data[i].error_rate == null){
                    validateIndex++;
                }else if(data[i].error_rate > 100){
                    validateIndex++;
                }
            }
            return validateIndex==0?false:true
        }else{
            return false;
        }
    }

    async getProductionCardData(productionId){
        const variables = {
            id : productionId
        }
        var query = gql`
            query ($id:String) {
                GetProduction (ProductionId: $id) {
                    ProductionV{
                        production_id
                        item_id
                        item_name
                        machine_id
                        machine_name
                        storage_id
                        storage_name
                        status
                        qty
                        ProductionDetailV{
                            production_id
                            material_id
                            material_name
                            qty
                            error_rate
                        }
                        production_start_date
                    }
                    Total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetProduction.ProductionV[0];
    }

    async getProducerQuery(){
        var query = gql`query{
            GetContact(ContactType:"Vendor"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value: result.data.GetContact[i].contact_id, 
                            label: result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    async getAllItemQuery(){
        var query = gql`query{
            GetProductMaster{
                ProductMaster{
                    item_id
                    product_code
                    item_name
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var itemData = result.data.GetProductMaster.ProductMaster;
        var data = [];
        if(itemData != null){
            for (let i = 0; i < itemData.length; i++) {
                var str = { value: itemData[i].item_id, 
                            label: itemData[i].product_code + " (" + itemData[i].item_name + ")"}
                data.push(str);
            }
        }

        return data;
    }
}

export default new ProductionCardService();